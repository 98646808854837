/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, StateCta, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "From the tranquil sounds of nature in the Rockies, to the incredible roar of the crowd at Sports Authority Field, Colorado is a state that needs to be heard just as much as seen. It’s a state where residents love music (like the Colorado Symphony Orchestra or Bluegrass bands) and to socialize over coffee or beer. But when your hearing starts to fade, this turns the vivid, unique sounds of the Centennial State into indistinguishable noise that’s tough to make out."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "roots-of-hearing-loss-in-the-centennial-state",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#roots-of-hearing-loss-in-the-centennial-state",
    "aria-label": "roots of hearing loss in the centennial state permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Roots of Hearing Loss in the Centennial State"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are plenty of ways in which someone can develop hearing loss in Colorado. First, work is a common cause of hearing impairment. From the roaring turbines of the state's hydroelectric plants, to aerospace factories and construction sites in Denver and Boulder, hundreds of thousands of Colorado residents are exposed to harmful noise levels at work. But you don't need to work in a noisy environment to sustain hearing loss. Colorado's highways are another major cause of impaired hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It's a big state, after all, and most residents need to drive long distances to reach their home and businesses. Highway traffic regularly peaks above the 80db, which is seen as dangerous by audiological experts, and a lifetime of driving can cause permanent damage to your hearing. Another source of exposure includes hunting, Colorado's favorite leisure activity. Rifles create massive amounts of noise (up to 170db is common), and a shocking number of hunters fail to wear adequate ear protection."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One University of Wisconsin survey in 2016 found that 95% of hunters surveyed had gone without proper ear protection in the past year. But that's not all: crowd noise at Broncos, Nuggets, and Avalanche games can contribute to hearing loss over time. This means that anybody in the State of Colorado is at risk of hearing loss, and that a huge number of people live their lives with some degree of hearing impairment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "understanding-hearing-loss-in-colorado",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understanding-hearing-loss-in-colorado",
    "aria-label": "understanding hearing loss in colorado permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understanding Hearing Loss in Colorado"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have hearing loss in one or both ears, or you have tinnitus, you're far from alone. According to the Colorado Commission for the Deaf and Hard of Hearing, 8.6% of Colorado residents over the age of 3 are classified as hearing impaired. That’s an estimated total of 446,000 people! Around 34% of those individuals most likely developed hearing loss through exposure to noise, a figure that's tripled since 1980 according to the CCDHH."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The number of individuals with hearing loss rises with age as well, with 30% of those over 60 reporting some degree of hearing loss. But what's so shocking about these numbers is how few people seek help. According to the NIH, 28.8 million Americans could benefit from hearing aids. The problem is, only 30% of those over 70 and just 16% of those aged 16-69 seek help for their hearing loss. In Colorado, that means over 300,000 people are living with poor hearing and haven't reached out to any professionals for help. So, what can be done?"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-a-hearing-solution-in-colorado",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-a-hearing-solution-in-colorado",
    "aria-label": "find a hearing solution in colorado permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find a Hearing Solution in Colorado"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The answer is to get in touch with hear.com. We are the world's fasting growing, leading provider of hearing aids. Our hearing aid experts are ready to give you one-on-one advice about hearing aids and put you in touch with a trusted audiologist or hearing care expert near you. Our nationwide network of over 1,600 audiologists and hearing care experts includes over 80 in the State of Colorado alone. So whether you're in Boulder, Denver, or down south in Durango, finding a convenient hearing care professional won't be a hassle with hear.com."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-your-hearing-back-on-track",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-your-hearing-back-on-track",
    "aria-label": "get your hearing back on track permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Your Hearing Back on Track"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you're a music lover or just want to enjoy the lively atmosphere of Broncos games, hearing loss can get in the way of your happiness. Contact hear.com today to find your perfect hearing loss solution. Simply fill out our online questionnaire to get a free, personal phone consultation with one of our hearing aid experts who will walk you through your options. Don't settle for poor hearing. Enjoy better hearing with hear.com."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
